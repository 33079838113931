import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "releases"
    }}>{`Releases`}</h1>
    <hr></hr>
    <p>{`As part of our commitment to providing dynamic and versatile services, Solus caters to a range of Integrated Library Systems/Library Management Systems. While the features, updates, and fixes mentioned below have been designed to enhance your experience, it's essential to note that their compatibility may vary based on your specific Integrated Library System/Library Management System. To ensure optimal compatibility and functionality, we recommend reaching out to your Integrated Library System/Library Management System provider directly. Their expertise in your unique configuration allows for accurate verification and alignment with Solus services. `}</p>
    <h6 {...{
      "id": "last-updated-august-19th-2024"
    }}>{`Last updated: August 19th, 2024`}</h6>
    <hr></hr>
    <h2 {...{
      "id": "202434sv"
    }}>{`2024.34sv`}</h2>
    <p><strong parentName="p">{`Server update`}</strong>{` (No app update required)`}<br />{`
August 19th, 2024 `}</p>
    <u>Features:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`New Translations:`}</strong>{` Added support for Hebrew, Pashto, Urdu, and Persian languages to enhance accessibility for a broader user base. To learn how to configure your app for different languages, please refer to the `}<a parentName="li" {...{
          "href": ""
        }}>{`Edit Language Options for Your App`}</a>{` page for more information. `}</li>
      <li parentName="ul"><strong parentName="li">{`Insignia Data Provider:`}</strong>{` Introduced compatibility with the Insignia Integrated Library System. `}<em parentName="li">{`Note: This requires changes on the backend. If you are interested in this as an option, please email `}<a parentName="em" {...{
            "href": "mailto:info@sol.us"
          }}>{`info@sol.us`}</a>{` to find out more.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`StackMap Integration:`}</strong>{` Configured for future support of StackMap to enhance library floor plan navigation. `}<em parentName="li">{`Note: Official support for StackMap is forthcoming in a future app update.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Call Numbers in Search Results:`}</strong>{` Added call numbers to search results when using Encore or WebPAC as search providers. `}<em parentName="li">{`Note: Currently only available for Sierra.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Hold Count:`}</strong>{` Introduced an option for the hold count to appear in the title detail display. `}<em parentName="li">{`Note: Currently only available for Sierra.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Enhanced Hoopla Records:`}</strong>{` Added support to enrich Hoopla content records with additional metadata from the ILS/LMS and other search providers, providing patrons with a more comprehensive and detailed information.  `}<em parentName="li">{`Note: Hoopla Integration with the Solus Library App is still in beta testing.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Enhance User Standing:`}</strong>{` Allows enforcement of user standing during Self-Service checkout. This ensures that patrons with blocks on their accounts will not be able to check out items, maintaining adherence to library polices. `}<em parentName="li">{`Note: Currently only available for Polaris.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Stat Group Support:`}</strong>{` Ability to add a Stat Group on transactions such as Create Hold, Checkout, and Renewal has been added to the Content Management System. This addition helps to track circulation usage from the Solus Library App. `}<em parentName="li">{`Note: Currently only available for Sierra.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Hiding Patron Fields:`}</strong>{` Hides specific patron fields from the ‘My Details’ section within My Account from displaying. This provides more control of the visibility and access to non-essential information. `}<em parentName="li">{`Note: Currently only available for Symphony.`}</em></li>
      <li parentName="ul"><strong parentName="li">{`Support for Locally Added Content:`}</strong>{` The AspenCat Search Provider now supports locally added content. `}<em parentName="li">{`If you are interested in this as an option, please email `}<a parentName="em" {...{
            "href": "mailto:info@sol.us"
          }}>{`info@sol.us`}</a>{`.`}</em></li>
    </ul>
    <u>Updates:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Wallet Pass Edit Option:`}</strong>{` You can now manage and update your own settings within the Wallet Pass settings in the Content Management System, providing greater flexibility and control of your app.`}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`eContent My Lists:`}</strong>{` Resolved an issue where eContent items were not appearing in My Lists. `}</li>
      <li parentName="ul"><strong parentName="li">{`BLUEcloud Commerce Payment Fix:`}</strong>{` Addressed an issue in the BLUEcloud Mobile app where the Stripe payment option was incorrectly displayed when a patron would attempt to pay a fine through BLUEcloud Commerce. `}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "202425"
    }}>{`2024.25`}</h2>
    <p>{`June 18th, 2024`}</p>
    <u>Features:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`AspenCat Search Provider:`}</strong>{` Patrons can now search for library materials using AspenCat directly within the app, expanding the range of available resources. `}<em parentName="li">{`Please note that this requires configuration changes on the backend. If you are interested in this as an option, please email `}<a parentName="em" {...{
            "href": "mailto:support@sol.us"
          }}>{`support@sol.us`}</a>{` to find out more.`}</em></li>
    </ul>
    <u>Updates:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Improved experience for Hoopla content when the user is not yet registered:`}</strong>{` Enhancements have been made to streamline the registration process for Hoopla, ensuring a smoother and more intuitive experience for users accessing Hoopla content for the first time.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "202417"
    }}>{`2024.17`}</h2>
    <p>{`May 28th, 2024`}</p>
    <u>Updates:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Event Actions:`}</strong>{` Added confirmation dialongs for actions taken against events, such as cenceling an event. New translation option added to the Content Management System - `}<em parentName="li">{`EventBook_GenericServerSuccess.`}</em>{` `}</li>
      <li parentName="ul"><strong parentName="li">{`Active Filter Indication:`}</strong>{` Selecting a format now properly shows it as 'active' and displays its facet value inside the drop-down option. `}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Equipment/Room Bookings Menu:`}</strong>{` Removed equipment/room booking menu option from My Account when this feature is not enabled. `}</li>
      <li parentName="ul"><strong parentName="li">{`Format Width Adjustment:`}</strong>{` Addressed scenarios where the short format list appeared wider than desired after transitioning from a longer list to a short one. `}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "202415"
    }}>{`2024.15:`}</h2>
    <p>{`April 26th, 2024`}</p>
    <u>Features:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Ability to add library card to Apple / Google Wallet:`}</strong>{` Patrons can now conveniently add their library card to Apple Wallet or Google Wallet for easy access and use. `}</li>
      <li parentName="ul"><strong parentName="li">{`Equipment Bookings:`}</strong>{` Patrons now can view equipment booked through eb#1 Events & Room Bookings. This feature introduces a new menu option within My Account called 'Equipment Bookings', providing seamless access to equipment reservation details. `}<em parentName="li">{`Note: Requires an eb#1 Events & Room Bookings connection.`}</em></li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://appdocs.sol.us/configure-ad-hoc-push-notifications"
          }}>{`Ad-hoc Push Notifications:`}</a></strong>{` Enjoy the flexibility of sending ad-hoc push notifications directly to the app, keeping patrons informed and engaged with important updates and announcements. `}</li>
      <li parentName="ul"><strong parentName="li">{`Customizable Highlights on Home Screen:`}</strong>{` Customers now have the option to select what types of highlights they want to display on the home screen of the app. These include: `}
        <ul parentName="li">
          <li parentName="ul">{`Checkouts - Overdue`}</li>
          <li parentName="ul">{`Checkouts - Due Soon`}</li>
          <li parentName="ul">{`Holds - Ready for Pickup`}</li>
          <li parentName="ul">{`Events - Today*`}</li>
          <li parentName="ul">{`Events - Upcoming*`}</li>
          <li parentName="ul">{`Room Bookings - Today*`}</li>
          <li parentName="ul">{`Resource Bookings - Today*`}
            <ul parentName="li">
              <li parentName="ul">{`*`}{` Require an events data provider to be configured to work and show up correctly. `}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Beacon Presence on Check-In:`}</strong>{` An additional security measure has been implemented in the Content Management System, allowing the app to continuously check for the presence of the check-in Bluetooth beacon while you are using the feature. Previously, without this enabled, the app only checked when you opened the feature, potentially allowing you to walk away from the beacon with the dialog still open.`}</li>
      <li parentName="ul"><strong parentName="li">{`Opacity Percentage for Home Screen Buttons:`}</strong>{` A new opacity percentage has been added, allowing customers to set the opacity percent for home screen button backgrounds. This feature enhances customization options and allows customers to tailor their app experience according to their preferences.`}</li>
      <li parentName="ul"><strong parentName="li">{`Filter Holds and Checkouts Display:`}</strong>{` Customers now can set the Content Management System to filter the holds and checkouts display by physical or digital within the app, providing more control and customization over their library experience. Note: This will add a dropdown menu to these screens.`}</li>
      <li parentName="ul"><strong parentName="li">{`Biometric Account Lock:`}</strong>{` The app now supports biometric account lock,allowing patrons to enhance the security of their accounts using biometric authentication methods such as fingerprint or face recognition.`}</li>
      <li parentName="ul"><strong parentName="li">{`Show Available Copiesby Default:`}</strong>{` You can now choose to display only available copies by default in the app, streamlining the browsing experience and making it easier to find and access available items.`}</li>
      <li parentName="ul"><strong parentName="li">{`IMMS `}<a parentName="strong" {...{
            "href": "https://lyngsoesystems.com/library/intelligent-material-management-system"
          }}>{`Intelligent Material Management Syste`}</a>{` Integration:`}</strong>{` This integration brings a seamless experience for patrons, allowing them to conveniently locate their items on a disorganized hold shelf directly from the app. `}<em parentName="li">{`Note: Currently only available for Sierra.`}</em></li>
    </ul>
    <u>Updates:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Improved RSS Feed Display:`}</strong>{` RSS feeds that are link able within a catalogue will now display properly within the app, ensuring a seamless browsing experience for patrons. `}</li>
      <li parentName="ul"><strong parentName="li">{`Cancellation Confirmation Check:`}</strong>{` A new confirmation check has been added for cancelling multiple holds, enhancing patron experience, and preventing accidental cancellations.`}</li>
      <li parentName="ul"><strong parentName="li">{`Clickable Web Links:`}</strong>{` Web links within meta data on the Item Details Display are now clickable and will open within the in-app browser, providing easy access to additional information.`}</li>
      <li parentName="ul"><strong parentName="li">{`ReCAPTCHA References Removal:`}</strong>{` References to ReCAPTCHA within the application's Preferences menu have been removed, streamlining the patron interface, and improving accessibility.`}</li>
      <li parentName="ul"><strong parentName="li">{`User Image Icon Removal:`}</strong>{` The user image icon within MyAccount has been removed, and the app will now display the patron's name with the option to select 'My Details', improving clarity and navigation.`}</li>
      <li parentName="ul"><strong parentName="li">{`HTML Element Blocking:`}</strong>{` The Content Management System will now block certain HTML elements, preventing unexpected rendering issues and ensuring consistent display of text. For instance, consider the use of HTML tags like < br >< /br >. This means that any text enclosed within HTML will be displayed as plain text, including the HTML markup itself. This applies to all HTML formatting in the Content Management System.`}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Self-Service Receipts Menu Fix:`}</strong>{` Receipts with no matching locations were causing the Self-Service Receipts menu option to break. This issue has been resolved, ensuring seamless access to receipts for all patrons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sort by Distance Reload Issue Fix:`}</strong>{` Previously, the 'Sort by Distance'feature had issues reloading after a patron had given access to their location. This issue has been fixed, providing accurate distance sorting functionality.`}</li>
      <li parentName="ul"><strong parentName="li">{`OverDrive Titles Incorrectly Displaying as Available:`}</strong>{` Resolved the issue where titles not consolidated (FRBRized) from OverDrive were inaccurately marked as “not currently available”.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "20245"
    }}>{`2024.5:`}</h2>
    <p>{`February 13th, 2024`}</p>
    <u>Updates:</u>
    <ul>
      <li parentName="ul">{`Thumbnails will now display for RSS feeds with media`}{`\\`}{`:content as the URL.`}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`The FOR PICKUP nugget will now correctly display on the home screen when there are items ready for pickup. `}</li>
    </ul>
    <hr></hr>
    <h2> 2024.3: </h2>
January 19th, 2024
    <u>Features:</u>
    <ul>
      <li parentName="ul">{`Ability to add events to the library channel - Optional setting in the Content Management System.`}</li>
      <li parentName="ul">{`Ability to filter loans by digital or physical - May not be available for all Integrated Library Systems/Library Management Systems.`}</li>
      <li parentName="ul">{`Support for eb#1 Events & Room Bookings event registration close time.`}</li>
      <li parentName="ul">{`Request for account deletion within app under Preferences - Optional setting in the Content Management System.`}</li>
    </ul>
    <u>Updates:</u>
    <ul>
      <li parentName="ul">{`Room booking history 'Payment Outstanding' to be converted into toogle instead of a drop down. `}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`The iOS barcode scanner was scanning too quickly and not reading the full barcode. This has been fixed to allow the full barcode to be read by the scanner. `}</li>
      <li parentName="ul">{`Event text search was previously not working when there was a space included. This problem was specific to iOS and has been resolved. `}</li>
      <li parentName="ul">{`Events for linked accounts will now correctly display within the app. `}</li>
      <li parentName="ul">{`The 'Channel_Time_Line' string, withing Edit Languages inside of the Content Management System, was showing as 'opening-opening'. This has been fixed to correctly display 'opening-closing'.`}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.11: </h2>
    <p>{`December 16th, 2023`}</p>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`When you had no lists created and you attempted to add an item to a list, the app wouldn't allow you to create your first list. This has been fixed to have the Create List option open directly.`}</li>
      <li parentName="ul">{`Attempting to delete your first created list would result in the list not properly being removed. This has been updated to allow the delete and removal of the first created list. `}</li>
    </ul>
    <u>Updates:</u>
    <ul>
      <li parentName="ul">{`Some OverDrive thumbnail art was being sent back to the app in a large format. This has been updated to set a limit to how large the thumbnail will display within the app.`}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.10: </h2>
    <p>{`November 15th, 2023`}</p>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`The My Lists menu option will display correctly in the My Account menu options without needing to add an item to a list first. `}</li>
      <li parentName="ul">{`Searching for a two letter search term would cause the app to throw an error. This has been fixed to allow searching for terms that are two characters or less. `}</li>
      <li parentName="ul">{`Reading Tracker sessions will properly update and accumulate the correct number of reading minutes. `}</li>
      <li parentName="ul">{`Searching for an event with two words in it will correctly display results for patrons to review. This was an issue specific to iOS. `}</li>
      <li parentName="ul">{`The events page will no longer display a "Booked" message when the user previously cancelled a booked event. `}</li>
    </ul>
    <u>Updates:</u>
    <ul>
      <li parentName="ul">{`The server will now decide if an item is overdue or not. This allows for better handling of different ILS/LMS platforms due dates being set to either 00:00 and others to 23:59. `}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.9: </h2>
    <p>{`October 10th, 2023`}</p>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`Resolved an issue where certain title detail screens were blank.`}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.8: </h2>
    <p>{`October 4th, 2023`}</p>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`A "auto firing error" message has been resolved. This issue would present itself when a patron would log into the app, log out, and then log back into another account all within a short period of time. `}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.7: </h2>
    <p>{`September 28th, 2023`}</p>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`An issue with self-check not recognizing when you're at a proper library location that is configured within the Content Management System has been fixed. The app previously only allowed self-check to work correctly when both check out and check in were enabled. `}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.6: </h2>
    <p>{`September 27th, 2023`}</p>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`Resolves an issue with scanning codabar barcodes for self-check`}</li>
      <li parentName="ul">{`Adds support for EAS for self-check`}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.5: </h2>
    <p>{`September 18th, 2023`}</p>
    <u>Updates:</u>
    <ul>
      <li parentName="ul">{`Home Carousel will be capped at 20 items. All items will remain visible when a patron selects 'More'. `}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`A login issue with iOS users running on 15.5 has been resoolved. Previously patrons operating on that OS version were experiencing a non-responsive login button. `}</li>
      <li parentName="ul">{`The codabar barcode reading error for scanning patron's barcodes has been resolved for iPhone users.`}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1.4: </h2>
    <p>{`August 30th, 2023`}</p>
    <u>Updates:</u>
    <ul>
      <li parentName="ul">{`The Reading Tracker ISBN search error message is now more consistent with other error messages in the app. The app will display 'No results.'`}</li>
      <li parentName="ul">{`Re-implemented the barcode scanner onto the new login screen.`}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`The 'Go home' button will correctly direct to the home screen instead of going back one page.`}</li>
    </ul>
    <hr></hr>
    <h2> 2023.1: </h2>
    <p>{`August 21st, 2023`}</p>
    <u>New Functionality:</u>
    <ul>
      <li parentName="ul">{`Brand new user interface:`}
        <ul parentName="li">
          <li parentName="ul">{`'Copy ISBN' button added to the Item Details Display.`}</li>
          <li parentName="ul">{`'Scroll to Top' button in Search Results.`}</li>
          <li parentName="ul">{`Filter Options within Search Results now displayed at the top for easier access to sorting.`}</li>
          <li parentName="ul">{`'More' button for long item descriptions in Item Details Display.`}</li>
          <li parentName="ul">{`Homescreen highlights holds/reservations that are ready for pickup.`}</li>
          <li parentName="ul">{`Homescreen highlights checkouts/loans that are overdue.`}</li>
          <li parentName="ul">{`Library Location's page design update.`}</li>
          <li parentName="ul">{`New Login Process/Screen - opens a new in-app browser for login. When a patron is logged out, the app will prompt them to login 	before interacting with anything within the app.`}</li>
          <li parentName="ul">{`Search bar, My Barcode, Self-Check, and My Account will be kept in view during scroll.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`My Lists functionality:`}</strong>{` Create and manage lists. (Standalone functionality in the app.)`}</li>
      <li parentName="ul"><strong parentName="li">{`Reading Tracker:`}</strong>{` like fitness tracking for reading, where users track their reading activity and unlock virtual awards.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fully integrated Events & Room Bookings:`}</strong>{` Ability to review current and past room bookings for own account and any linked account(s). Calendar month display for events with the option to view a specific day. Review events that you or any linked account(s) are currently waitlisted for.`}</li>
      <li parentName="ul">{`Over 30 languages, including right-to-left language support for Arabic, Hebrew, and Urdu. To learn how to configure translations for your app, please refer to the `}<a parentName="li" {...{
          "href": "https://appdocs.sol.us/edit-languages-for-your-app"
        }}>{`Edit Language Options for Your App`}</a>{` page for more information. `}</li>
      <li parentName="ul"><strong parentName="li">{`Format Rollup:`}</strong>{` FRBRized display of available formats under a single search return. (Not currently available for Syndetics. Coming in a future update.)`}</li>
      <li parentName="ul">{`Enhanced Multi-Part RFID Tag Support - for checking out items such as box sets with multiple RFID tags.`}</li>
      <li parentName="ul">{`Chromebook version.`}</li>
      <li parentName="ul">{`Ability for patrons to switch templates if enabled.`}</li>
      <li parentName="ul">{`Ability to update app logo from within the Content Management System `}
        <ul parentName="li">
          <li parentName="ul">{`No longer requires multiple variants or background embedding. `}</li>
          <li parentName="ul">{`App will notice changes on start and reload. `}</li>
          <li parentName="ul">{`Landscape variant removed.`}</li>
        </ul>
      </li>
    </ul>
    <u>Updates:</u>
    <ul>
      <li parentName="ul">{`Option to log out of all devices associated with the account or the specific one being used.`}</li>
      <li parentName="ul">{`Removed 'Flow' layout option from the Content Management System and set the app layout option to 'Scroll' by default.`}</li>
      <li parentName="ul">{`Barcodes scroll horizontally - arrow buttons for extra clarity and accessibility.`}</li>
      <li parentName="ul">{`Extended color options within the Content Management system to control home and notice buttons. (Keeping text color black or white is strongly suggested for accessibility compliance.) To learn more about this, please refer to the `}<a parentName="li" {...{
          "href": "https://appdocs.sol.us/configure-ui-colors"
        }}>{`Configure UI Colors`}</a>{` page for more information. `}</li>
      <li parentName="ul">{`More header/tab points to aid in navigation and non-visual page explanation for screen readers.`}</li>
      <li parentName="ul">{`Devices font change will be applied while the app is running and will no longer require a reload.`}</li>
      <li parentName="ul">{`Catalogue Searches are cached for 30 minutes within the app. Re-searching for the exact same term will be near instant. Please note that search stats may be lower as a result of this. `}</li>
      <li parentName="ul">{`Patron account information is cached for 30 minutes after viewing. Re-viewing will then be near instant. Please note that record view stats may be lower as result of this. `}</li>
      <li parentName="ul">{`Ability to sort charges / fines by any linked accounts and the amount of the fine. `}</li>
    </ul>
    <u>Fixes:</u>
    <ul>
      <li parentName="ul">{`Barcodes no longer cut off in landscape mode.`}</li>
      <li parentName="ul">{`'More' carousel button no longer obscures the book carousel title.`}</li>
      <li parentName="ul">{`'X' button is easier to select when there are three or more linked accounts.`}</li>
      <li parentName="ul">{`Facebook links are now working and directing correctly from within the app.`}</li>
      <li parentName="ul">{`Hold list will automatically update after a patron checks out an item they have on hold.`}</li>
      <li parentName="ul">{`Auto apply full brightness on the device when barcodes are being viewed.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      